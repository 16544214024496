'use strict';

angular.module('enervexSalesappApp').controller('FanPhasesCtrl', function($scope, FanPhase, _) {
	$scope.allFanPhases = [];
	$scope.offset = 0;
	$scope.limit = 50;
	function fetch() {
		FanPhase.query({
			offset:$scope.offset,
			limit: $scope.limit
		}).$promise.then(function(fanPhases){
			if (fanPhases.length > 0) {
				$scope.allFanPhases = $scope.allFanPhases.concat(fanPhases);
				$scope.offset = $scope.offset + $scope.limit;
				fetch();
			} else {
				$scope.fanPhases = _.sortBy($scope.allFanPhases, function(bomRule){
					return bomRule.category
				})
			}
		});
	}
	fetch();
	$scope.delete = function(bomRule) {
		FanPhase.delete({
			id: bomRule._id
		});
		$scope.fanPhases = $scope.allFanPhases = _.filter($scope.allFanPhases, function(r){
			return r._id != bomRule._id
		})
		// $scope.applyFilter()
	}
	// $scope.applyFilter = function() {
	// 	var nameReg = ($scope.term && $scope.term != '') ? RegExp($scope.term, "i") : null;

	// 	$scope.fanPhases = _.filter($scope.allFanPhases, function(rule){
	// 		if (nameReg && !(nameReg.test(rule.action) || nameReg.test(rule.filter) || (rule.product && nameReg.test(rule.product.name)) || nameReg.test(rule.legacyId) )) {
	// 			return false;
	// 		} else if ($scope.category && $scope.category != '' && $scope.category != rule.category) {
	// 			return false;
	// 		} else {
	// 			return true
	// 		}
	// 	})
	// }
});
